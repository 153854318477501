import { PropsWithChildren } from 'react';

import CheckCircle from '@static/svg/CheckCircle';
import ExclamationMark from '@static/svg/ExclamationMark';
import KemiLine from '@static/svg/KemiLine';
import { styled } from '@styles/stitches.config';

export const GoodToastItem = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ToastItem type={'good'}>
      <CheckCircle color={'black'} />
      <Content>{children}</Content>
    </ToastItem>
  );
};

export const BadToastItem = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ToastItem type={'bad'}>
      <ExclamationMark color={'white'} />
      <Content>{children}</Content>
    </ToastItem>
  );
};

export const InfoToastItem = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ToastItem type={'info'}>
      <InfoIcon />
      <Content>{children}</Content>
    </ToastItem>
  );
};

const Content = styled('div', {
  wordBreak: 'break-all',
  alignSelf: 'center',
  textAlign: 'left',
});

const InfoIcon = styled(KemiLine, {
  path: {
    stroke: '$white',
  },
});

const ToastItem = styled('div', {
  width: 'inherit',
  height: 'fit-content',
  minHeight: 48,
  background: 'info',
  color: 'white',
  padding: 12,
  gridColumnWithGap: 8,
  justifyContent: 'start',
  alignItems: 'start',
  cursor: 'pointer',
  variants: {
    type: {
      good: {
        backgroundColor: '$mint100',
        color: '$black',
      },
      bad: {
        backgroundColor: '$red100',
      },
      info: {
        backgroundColor: '$primary200',
      },
    },
  },
});
