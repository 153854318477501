import { useEffect } from 'react';

import Button from '@design0.2/Button/Button';
import CheckBoxInput from '@design0.2/Inputs/CheckBoxInput';
import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';
import { styled } from '@styles/stitches.config';

const TermsSelection = () => {
  const signInAndUpModal = useSignInAndUpModal();

  useEffect(() => {
    logFirebase(
      UserInteractionType.NONE,
      EVENT_TAG.SIGN_IN_AND_UP.SCREEN_OPEN_TERMS_N_CONDITIONS_CHECK
    );
  }, []);

  const confirm = () => {
    logFirebase(
      UserInteractionType.NONE,
      EVENT_TAG.SIGN_IN_AND_UP.TERMS_N_CONDITIONS_CHECK_CLICK
    );

    signInAndUpModal.changeMode('signUp');
  };

  return (
    <div>
      <TitleSection>
        <TitleText>{i18n.t('k_terms_check')}</TitleText>
        <SubtitleText>
          {i18n.t('k_terms_selection_modal_subtitle')}
        </SubtitleText>
      </TitleSection>
      <AllSelectionSection>
        <CheckBoxInput
          label={i18n.t('k_agree_all')}
          checked={signInAndUpModal.allTermsSelected}
          onChange={(e) =>
            e.target.checked
              ? signInAndUpModal.selectAllTerms()
              : signInAndUpModal.deselectAllTerms()
          }
        />
      </AllSelectionSection>
      <Divider />
      <SelectionSection>
        <Selection>
          <CheckBoxInput
            label={i18n.t('k_required_term_template', {
              term: i18n.t('k_terms_of_use_2'),
            })}
            checked={signInAndUpModal.selectedTerms.service}
            onChange={(e) =>
              signInAndUpModal.updateTerms({ service: e.target.checked })
            }
          />
          <DetailAnchor
            target={'_blank'}
            href={'https://kemi.oopy.io/25277795-4af9-46dc-a3e2-422ab63d089f'}
            rel={'noreferrer'}
          >
            {i18n.t('k_check')}
          </DetailAnchor>
        </Selection>
        <Selection>
          <CheckBoxInput
            label={i18n.t('k_required_term_template', {
              term: i18n.t('k_privacy_policy'),
            })}
            checked={signInAndUpModal.selectedTerms.privacy}
            onChange={(e) =>
              signInAndUpModal.updateTerms({ privacy: e.target.checked })
            }
          />
          <DetailAnchor
            target={'_blank'}
            href={'https://kemi.oopy.io/0b0735eb-cf1d-4cb6-8cef-b09f2971a3c9'}
            rel={'noreferrer'}
          >
            {i18n.t('k_check')}
          </DetailAnchor>
        </Selection>
        <Selection>
          <CheckBoxInput
            label={i18n.t('k_optional_term_template', {
              term: i18n.t('k_allow_marketing_notification'),
            })}
            checked={signInAndUpModal.selectedTerms.marketing}
            onChange={(e) =>
              signInAndUpModal.updateTerms({ marketing: e.target.checked })
            }
          />
          <DetailAnchor
            target={'_blank'}
            href={'https://kemi.oopy.io/dd5f8a9f-7007-4e28-be17-fa5951d207f0'}
            rel={'noreferrer'}
          >
            {i18n.t('k_check')}
          </DetailAnchor>
        </Selection>
      </SelectionSection>
      <ConfirmSection>
        <Button onClick={confirm}>{i18n.t('k_confirm')}</Button>
      </ConfirmSection>
    </div>
  );
};

const TitleSection = styled('div', {
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const TitleText = styled('span', { fontType: 'heading3', color: '$black' });

const SubtitleText = styled('span', {
  fontType: 'captionRegular',
  color: '$black',
});

const AllSelectionSection = styled('div', { padding: 16 });

const Divider = styled('div', {
  margin: '0 16px',
  height: 1,
  backgroundColor: '$grey20',
});

const SelectionSection = styled('div', {
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const Selection = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const DetailAnchor = styled('a', {
  fontType: 'captionRegular',
  color: '$grey100',
});

const ConfirmSection = styled('div', { padding: 16 });

export default TermsSelection;
