import { useEffect, useMemo } from 'react';

import OAuthProvider from './OAuthProvider';

import CheckBoxInput from '@design0.2/Inputs/CheckBoxInput';
import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';
import { styled } from '@styles/stitches.config';

const SignUp = () => {
  const signInAndUpModal = useSignInAndUpModal();

  const titleText = useMemo(() => {
    switch (signInAndUpModal.purpose) {
      case 'order':
      case 'checkout':
        return i18n.t('k_sign_up_modal_title_buy_mode');
      default:
        return i18n.t('k_sign_up_modal_title_normal_mode');
    }
  }, [signInAndUpModal.purpose]);

  const checkboxLabel = useMemo(() => {
    if (!signInAndUpModal.isTermsSelectionDirty) {
      return i18n.t('k_agree_all_terms');
    }

    if (signInAndUpModal.allTermsSelected) {
      return i18n.t('k_agree_all_terms');
    }

    if (signInAndUpModal.shouldSelectRequiredTermsFlag) {
      return i18n.t('k_agree_all_terms');
    }

    return i18n.t('k_agree_terms');
  }, [
    signInAndUpModal.isTermsSelectionDirty,
    signInAndUpModal.allTermsSelected,
    signInAndUpModal.shouldSelectRequiredTermsFlag,
  ]);

  const selectedTermsText = useMemo(() => {
    const serviceText = i18n.t('k_terms_of_use_2');
    const privacyText = i18n.t('k_privacy_policy');
    const marketingTest = i18n.t('k_optional_term_template_2', {
      term: i18n.t('k_allow_marketing_notification'),
    });

    const allText = [serviceText, privacyText, marketingTest].join(', ');

    if (!signInAndUpModal.isTermsSelectionDirty) {
      return allText;
    }

    if (signInAndUpModal.shouldSelectRequiredTermsFlag) {
      return allText;
    }

    const temp = [];

    if (signInAndUpModal.selectedTerms.service) {
      temp.push(serviceText);
    }

    if (signInAndUpModal.selectedTerms.privacy) {
      temp.push(privacyText);
    }

    if (signInAndUpModal.selectedTerms.marketing) {
      temp.push(marketingTest);
    }

    return temp.join(', ');
  }, [
    signInAndUpModal.isTermsSelectionDirty,
    signInAndUpModal.shouldSelectRequiredTermsFlag,
    signInAndUpModal.selectedTerms,
  ]);

  useEffect(() => {
    logFirebase(
      UserInteractionType.NONE,
      EVENT_TAG.SIGN_IN_AND_UP.SCREEN_OPEN_SIGNUP_POPUP
    );
  }, []);

  return (
    <div>
      <Title>{titleText}</Title>
      <TermsSection>
        <TermsActions>
          <CheckBoxInputWrapper
            hasError={signInAndUpModal.shouldSelectRequiredTermsFlag}
          >
            <CheckBoxInput
              label={checkboxLabel}
              checked={
                signInAndUpModal.allRequiredTermsSelected ||
                signInAndUpModal.allTermsSelected
              }
              onChange={(e) =>
                e.target.checked
                  ? signInAndUpModal.selectAllTerms()
                  : signInAndUpModal.deselectAllTerms()
              }
            />
          </CheckBoxInputWrapper>
          <TermsSelectionButton
            onClick={() => signInAndUpModal.changeMode('termsDetail')}
          >
            {i18n.t('k_check')}
          </TermsSelectionButton>
        </TermsActions>
        <SelectedAgreementItems>{selectedTermsText}</SelectedAgreementItems>
      </TermsSection>
      <SignUpProvidersSection>
        <OAuthProvider
          provider={'kakao'}
          type={'signUp'}
          onClick={() => signInAndUpModal.proceedWithOAuth('kakao')}
        />
        <OAuthProvider
          provider={'naver'}
          type={'signUp'}
          onClick={() => signInAndUpModal.proceedWithOAuth('naver')}
        />
        <OAuthProvider
          provider={'google'}
          type={'signUp'}
          onClick={() => signInAndUpModal.proceedWithOAuth('google')}
        />
        <OAuthProvider
          provider={'facebook'}
          type={'signUp'}
          onClick={() => signInAndUpModal.proceedWithOAuth('facebook')}
        />
      </SignUpProvidersSection>
      <LoginSection>
        <LoginLeadText>{i18n.t('k_already_have_an_account')}</LoginLeadText>
        <LoginButton onClick={() => signInAndUpModal.changeMode('signIn')}>
          {i18n.t('k_sign_in')}
        </LoginButton>
      </LoginSection>
      {signInAndUpModal.purpose === 'order' && (
        <NonMemberButton onClick={signInAndUpModal.buyWithNonMember}>
          {i18n.t('k_buy_with_non_member')}
        </NonMemberButton>
      )}
    </div>
  );
};

const Title = styled('div', {
  fontType: 'heading3',
  color: '$black',
  padding: 16,
});

const TermsSection = styled('div', {
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const TermsActions = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '$black',
});

const CheckBoxInputWrapper = styled('div', {
  variants: {
    hasError: {
      true: {
        '& span': {
          color: '$red100',
        },
      },
    },
  },
});

const TermsSelectionButton = styled('span', {
  fontType: 'captionRegular',
  color: '$grey100',
  cursor: 'pointer',
});

const SelectedAgreementItems = styled('span', {
  fontType: 'captionRegular',
  color: '$grey100',
});

const SignUpProvidersSection = styled('div', {
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const LoginSection = styled('div', {
  padding: 16,
  display: 'flex',
  alignItems: 'center',
  gap: 16,
});

const LoginLeadText = styled('span', {
  fontType: 'bodyRegular',
  color: '$black',
});

const LoginButton = styled('div', {
  fontType: 'bodyBold',
  color: '$black',
  cursor: 'pointer',
});

const NonMemberButton = styled('div', {
  padding: 16,
  fontType: 'bodyRegular',
  color: '$grey100',
  cursor: 'pointer',
});

export default SignUp;
