import * as Dialog from '@radix-ui/react-dialog';
import React, { ReactNode, useRef } from 'react';

import { keyframes, styled } from '@styles/stitches.config';

type ModalProps = {
  isOpen: boolean;
  onClose?: (...args: any[]) => void;
  children: ({
    onClose,
  }: {
    onClose: (
      e: React.MouseEvent<Element, MouseEvent>,
      isChildren?: boolean
    ) => void;
  }) => ReactNode[] | ReactNode;
};

const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  const backgroundRef = useRef<HTMLDivElement>(null);

  const handleClose = (
    e: React.MouseEvent<Element, MouseEvent>,
    isChildren?: boolean
  ) => {
    if (!backgroundRef.current || !onClose) return;

    if (e.target === backgroundRef.current || isChildren) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={(open) => !open && handleClose}>
      <Dialog.Portal>
        <DialogOveray ref={backgroundRef} onClick={handleClose}>
          <Dialog.Content asChild onOpenAutoFocus={(e) => e.preventDefault()}>
            {children({ onClose: handleClose })}
          </Dialog.Content>
        </DialogOveray>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const fadeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

const DialogOveray = styled(Dialog.Overlay, {
  display: 'flex',
  position: 'fixed',
  top: 0,
  left: 0,
  justifyContent: 'center',
  alignItems: 'flex-end',
  width: '100%',
  height: '100%',
  zIndex: '$modal',
  backgroundColor: 'rgba(0, 0, 0, 0.64)',
  animation: `${fadeIn} 300ms`,
});

export default Modal;
