import React from 'react';

import Box from '../../atoms/Box';
import Modal from '../../atoms/Modal';
import Text from '../../atoms/Text';

import Button from '@global/components/atoms/Button';
import { useConfirmModalStore } from '@global/store/confirmModalStore';
import { styled } from '@styles/stitches.config';

const ConfirmModal = () => {
  const {
    isOpen,
    title,
    description,
    descriptionAlign = 'center',
    confirmText,
    cancelText,
    actions,
    close,
  } = useConfirmModalStore();

  const handleConfirm = async () => {
    if (actions?.confirm) {
      await actions.confirm();
    }

    close();
  };
  const handleCancel = async () => {
    if (actions?.cancel) {
      await actions.cancel();
    }

    close();
  };
  const handleClose = async () => {
    if (actions?.backgroundClick) {
      await actions.backgroundClick();
    }

    close();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      {() => {
        return (
          <Container>
            <InnerContainer>
              {title && <Title font={'bodyBold'}>{title}</Title>}
              {description && (
                <Description align={descriptionAlign} font={'bodyRegular'}>
                  {description}
                </Description>
              )}
              <Buttons>
                {actions?.cancel && (
                  <Button
                    className={'button'}
                    type={'line'}
                    onClick={handleCancel}
                  >
                    {cancelText}
                  </Button>
                )}
                <Button className={'button'} onClick={handleConfirm}>
                  {confirmText}
                </Button>
              </Buttons>
            </InnerContainer>
          </Container>
        );
      }}
    </Modal>
  );
};

const Container = styled(Box, {
  '@mobile': {
    padding: '24px 16px 16px',
    borderRadius: 16,
    backgroundColor: '$white',
    position: 'fixed',
    right: 16,
    bottom: 16,
    left: 16,
  },

  '@mediaDesktop': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
});

const InnerContainer = styled(Box, {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: 24,
  alignItems: 'center',

  '@mediaDesktop': {
    padding: '24px 16px 16px',
    borderRadius: 16,
    backgroundColor: '$white',
    width: 360,
  },
});

const Title = styled(Text, {
  textAlign: 'center',
});

const Description = styled(Text, {
  variants: {
    align: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
    },
  },
});

const Buttons = styled(Box, {
  display: 'flex',

  '.button + .button': {
    marginLeft: 8,
    paddingRight: 8,
    paddingLeft: 8,
  },
});

export default ConfirmModal;
