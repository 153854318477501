import AddCardBlack from './asset-components/AddCardBlack.generated';
import AddCardDisabled from './asset-components/AddCardDisabled.generated';
import AddCardWhite from './asset-components/AddCardWhite.generated';
import AddBlack from './asset-components/AddBlack.generated';
import AddDisabled from './asset-components/AddDisabled.generated';
import AddSmallBlack from './asset-components/AddSmallBlack.generated';
import AddSmallDisabled from './asset-components/AddSmallDisabled.generated';
import AddSmallWhite from './asset-components/AddSmallWhite.generated';
import AddWhite from './asset-components/AddWhite.generated';
import AlignCenterBlack from './asset-components/AlignCenterBlack.generated';
import AlignCenterDisabled from './asset-components/AlignCenterDisabled.generated';
import AlignCenterWhite from './asset-components/AlignCenterWhite.generated';
import AlignLeftBlack from './asset-components/AlignLeftBlack.generated';
import AlignLeftDisabled from './asset-components/AlignLeftDisabled.generated';
import AlignLeftWhite from './asset-components/AlignLeftWhite.generated';
import AlignRightBlack from './asset-components/AlignRightBlack.generated';
import AlignRightDisabled from './asset-components/AlignRightDisabled.generated';
import AlignRightWhite from './asset-components/AlignRightWhite.generated';
import ArrayGallaryBlack from './asset-components/ArrayGallaryBlack.generated';
import ArrayGallaryDisabled from './asset-components/ArrayGallaryDisabled.generated';
import ArrayGallaryWhite from './asset-components/ArrayGallaryWhite.generated';
import ArrayListBlack from './asset-components/ArrayListBlack.generated';
import ArrayListDisabled from './asset-components/ArrayListDisabled.generated';
import ArrayListWhite from './asset-components/ArrayListWhite.generated';
import ArrowRightBlack from './asset-components/ArrowRightBlack.generated';
import ArrowRightDisabled from './asset-components/ArrowRightDisabled.generated';
import ArrowRightWhite from './asset-components/ArrowRightWhite.generated';
import BinBlack from './asset-components/BinBlack.generated';
import BinDisabled from './asset-components/BinDisabled.generated';
import BinSmallBlack from './asset-components/BinSmallBlack.generated';
import BinSmallDisabled from './asset-components/BinSmallDisabled.generated';
import BinSmallWhite from './asset-components/BinSmallWhite.generated';
import BinWhite from './asset-components/BinWhite.generated';
import CalendarBlack from './asset-components/CalendarBlack.generated';
import CalendarDisabled from './asset-components/CalendarDisabled.generated';
import CalendarSmallBlack from './asset-components/CalendarSmallBlack.generated';
import CalendarSmallDisabled from './asset-components/CalendarSmallDisabled.generated';
import CalendarSmallWhite from './asset-components/CalendarSmallWhite.generated';
import CalendarWhite from './asset-components/CalendarWhite.generated';
import CartBlack from './asset-components/CartBlack.generated';
import CartDisabled from './asset-components/CartDisabled.generated';
import CartWhite from './asset-components/CartWhite.generated';
import CheckCircleBlack from './asset-components/CheckCircleBlack.generated';
import CheckCircleDisabled from './asset-components/CheckCircleDisabled.generated';
import CheckCircleFilled from './asset-components/CheckCircleFilled.generated';
import CheckCircleFilledDisabled from './asset-components/CheckCircleFilledDisabled.generated';
import CheckCircleWhite from './asset-components/CheckCircleWhite.generated';
import CheckBlackWithWhite from './asset-components/CheckBlackWithWhite.generated';
import CheckBlack from './asset-components/CheckBlack.generated';
import CheckDisabled from './asset-components/CheckDisabled.generated';
import CheckSmallBlack from './asset-components/CheckSmallBlack.generated';
import CheckSmallDisabled from './asset-components/CheckSmallDisabled.generated';
import CheckSmallWhite from './asset-components/CheckSmallWhite.generated';
import CheckWhite from './asset-components/CheckWhite.generated';
import CheckboxChecked from './asset-components/CheckboxChecked.generated';
import CheckboxCheckedDisabled from './asset-components/CheckboxCheckedDisabled.generated';
import CheckboxUnchecked from './asset-components/CheckboxUnchecked.generated';
import CheckboxUncheckedDisabled from './asset-components/CheckboxUncheckedDisabled.generated';
import ClockBlack from './asset-components/ClockBlack.generated';
import ClockDisabled from './asset-components/ClockDisabled.generated';
import ClockSmallBlack from './asset-components/ClockSmallBlack.generated';
import ClockSmallDisabled from './asset-components/ClockSmallDisabled.generated';
import ClockSmallWhite from './asset-components/ClockSmallWhite.generated';
import ClockWhite from './asset-components/ClockWhite.generated';
import CloseBlack from './asset-components/CloseBlack.generated';
import CloseDisabled from './asset-components/CloseDisabled.generated';
import CloseSmallBlack from './asset-components/CloseSmallBlack.generated';
import CloseSmallDisabled from './asset-components/CloseSmallDisabled.generated';
import CloseSmallWhite from './asset-components/CloseSmallWhite.generated';
import CloseWhite from './asset-components/CloseWhite.generated';
import DeleteBlack from './asset-components/DeleteBlack.generated';
import DeleteDisabled from './asset-components/DeleteDisabled.generated';
import DeleteWhite from './asset-components/DeleteWhite.generated';
import EditDesignBlack from './asset-components/EditDesignBlack.generated';
import EditDesignDisabled from './asset-components/EditDesignDisabled.generated';
import EditDesignWhite from './asset-components/EditDesignWhite.generated';
import EditBlack from './asset-components/EditBlack.generated';
import EditDisabled from './asset-components/EditDisabled.generated';
import EditSmallBlack from './asset-components/EditSmallBlack.generated';
import EditSmallDisabled from './asset-components/EditSmallDisabled.generated';
import EditSmallWhite from './asset-components/EditSmallWhite.generated';
import EditWhite from './asset-components/EditWhite.generated';
import ExclamationMarkCircleFilledRed from './asset-components/ExclamationMarkCircleFilledRed.generated';
import FacebookFilled from './asset-components/FacebookFilled.generated';
import FavoriteBlack from './asset-components/FavoriteBlack.generated';
import FavoriteDisabled from './asset-components/FavoriteDisabled.generated';
import FavoriteFilled from './asset-components/FavoriteFilled.generated';
import FavoriteFilledDisabled from './asset-components/FavoriteFilledDisabled.generated';
import FavoriteLargeBlack from './asset-components/FavoriteLargeBlack.generated';
import FavoriteLargeFilled from './asset-components/FavoriteLargeFilled.generated';
import FavoriteWhite from './asset-components/FavoriteWhite.generated';
import GoogleBlack from './asset-components/GoogleBlack.generated';
import GoogleDisabled from './asset-components/GoogleDisabled.generated';
import GoogleFilled from './asset-components/GoogleFilled.generated';
import GoogleWhite from './asset-components/GoogleWhite.generated';
import HomeBlack from './asset-components/HomeBlack.generated';
import HomeDisabled from './asset-components/HomeDisabled.generated';
import HomeWhite from './asset-components/HomeWhite.generated';
import ImageBlack from './asset-components/ImageBlack.generated';
import ImageDisabled from './asset-components/ImageDisabled.generated';
import ImageWhite from './asset-components/ImageWhite.generated';
import InstagramBlack from './asset-components/InstagramBlack.generated';
import KakaoBlack from './asset-components/KakaoBlack.generated';
import KakaoDisabled from './asset-components/KakaoDisabled.generated';
import KakaoWhite from './asset-components/KakaoWhite.generated';
import LikeBlack from './asset-components/LikeBlack.generated';
import LikeDisabled from './asset-components/LikeDisabled.generated';
import LikeFilled from './asset-components/LikeFilled.generated';
import LikeFilledDisabled from './asset-components/LikeFilledDisabled.generated';
import LikeWhite from './asset-components/LikeWhite.generated';
import MenuBlack from './asset-components/MenuBlack.generated';
import MenuDisabled from './asset-components/MenuDisabled.generated';
import MenuWhite from './asset-components/MenuWhite.generated';
import MoveBlack from './asset-components/MoveBlack.generated';
import MoveDisabled from './asset-components/MoveDisabled.generated';
import MoveWhite from './asset-components/MoveWhite.generated';
import MusicBlack from './asset-components/MusicBlack.generated';
import MusicDisabled from './asset-components/MusicDisabled.generated';
import MusicWhite from './asset-components/MusicWhite.generated';
import NaverBlack from './asset-components/NaverBlack.generated';
import NaverDisabled from './asset-components/NaverDisabled.generated';
import NaverWhite from './asset-components/NaverWhite.generated';
import NaverblogBlack from './asset-components/NaverblogBlack.generated';
import NotificationRingingBlack from './asset-components/NotificationRingingBlack.generated';
import NotificationRingingDisabled from './asset-components/NotificationRingingDisabled.generated';
import NotificationRingingWhite from './asset-components/NotificationRingingWhite.generated';
import NotificationBlack from './asset-components/NotificationBlack.generated';
import NotificationDisabled from './asset-components/NotificationDisabled.generated';
import NotificationWhite from './asset-components/NotificationWhite.generated';
import PersonalInformationBlack from './asset-components/PersonalInformationBlack.generated';
import PersonalInformationDisabled from './asset-components/PersonalInformationDisabled.generated';
import PersonalInformationWhite from './asset-components/PersonalInformationWhite.generated';
import PhoneBlack from './asset-components/PhoneBlack.generated';
import PhoneDisabled from './asset-components/PhoneDisabled.generated';
import PhoneWhite from './asset-components/PhoneWhite.generated';
import PowerBlack from './asset-components/PowerBlack.generated';
import PowerDisabled from './asset-components/PowerDisabled.generated';
import PowerWhite from './asset-components/PowerWhite.generated';
import PrivacyPolicyBlack from './asset-components/PrivacyPolicyBlack.generated';
import PrivacyPolicyDisabled from './asset-components/PrivacyPolicyDisabled.generated';
import PrivacyPolicyWhite from './asset-components/PrivacyPolicyWhite.generated';
import ProfileBlack from './asset-components/ProfileBlack.generated';
import ProfileDisabled from './asset-components/ProfileDisabled.generated';
import ProfileWhite from './asset-components/ProfileWhite.generated';
import RadioChecked from './asset-components/RadioChecked.generated';
import RadioCheckedDisabled from './asset-components/RadioCheckedDisabled.generated';
import RadioUnchecked from './asset-components/RadioUnchecked.generated';
import RadioUncheckedDisabled from './asset-components/RadioUncheckedDisabled.generated';
import RatioLandscapeMBlack from './asset-components/RatioLandscapeMBlack.generated';
import RatioLandscapeSBlack from './asset-components/RatioLandscapeSBlack.generated';
import RatioPortraitBlack from './asset-components/RatioPortraitBlack.generated';
import RatioSquareBlack from './asset-components/RatioSquareBlack.generated';
import ReplyBlack from './asset-components/ReplyBlack.generated';
import ReplyDisabled from './asset-components/ReplyDisabled.generated';
import ReplyWhite from './asset-components/ReplyWhite.generated';
import SearchBlack from './asset-components/SearchBlack.generated';
import SearchDisabled from './asset-components/SearchDisabled.generated';
import SearchWhite from './asset-components/SearchWhite.generated';
import SettingsBlack from './asset-components/SettingsBlack.generated';
import SettingsDisabled from './asset-components/SettingsDisabled.generated';
import SettingsWhite from './asset-components/SettingsWhite.generated';
import ShareBlack from './asset-components/ShareBlack.generated';
import ShareDisabled from './asset-components/ShareDisabled.generated';
import ShareWhite from './asset-components/ShareWhite.generated';
import ShopBlack from './asset-components/ShopBlack.generated';
import ShopDisabled from './asset-components/ShopDisabled.generated';
import ShopWhite from './asset-components/ShopWhite.generated';
import SmallArrowDownBlack from './asset-components/SmallArrowDownBlack.generated';
import SmallArrowDownDisabled from './asset-components/SmallArrowDownDisabled.generated';
import SmallArrowDownSmallBlack from './asset-components/SmallArrowDownSmallBlack.generated';
import SmallArrowDownSmallWhite from './asset-components/SmallArrowDownSmallWhite.generated';
import SmallArrowDownWhite from './asset-components/SmallArrowDownWhite.generated';
import SmallArrowRightBlack from './asset-components/SmallArrowRightBlack.generated';
import SmallArrowRightDisabled from './asset-components/SmallArrowRightDisabled.generated';
import SmallArrowRightWhite from './asset-components/SmallArrowRightWhite.generated';
import SmallArrowUpBlack from './asset-components/SmallArrowUpBlack.generated';
import SmallArrowUpDisabled from './asset-components/SmallArrowUpDisabled.generated';
import SmallArrowUpWhite from './asset-components/SmallArrowUpWhite.generated';
import SocialMediaBlack from './asset-components/SocialMediaBlack.generated';
import SortBlack from './asset-components/SortBlack.generated';
import SortDisabled from './asset-components/SortDisabled.generated';
import SortWhite from './asset-components/SortWhite.generated';
import SubtractBlack from './asset-components/SubtractBlack.generated';
import SubtractDisabled from './asset-components/SubtractDisabled.generated';
import SubtractSmallBlack from './asset-components/SubtractSmallBlack.generated';
import SubtractSmallDisabled from './asset-components/SubtractSmallDisabled.generated';
import SubtractSmallWhite from './asset-components/SubtractSmallWhite.generated';
import SubtractWhite from './asset-components/SubtractWhite.generated';
import TextMarginBottomNTop from './asset-components/TextMarginBottomNTop.generated';
import TextMarginBottom from './asset-components/TextMarginBottom.generated';
import TextMarginDefault from './asset-components/TextMarginDefault.generated';
import TextMarginTop from './asset-components/TextMarginTop.generated';
import TiktokBlack from './asset-components/TiktokBlack.generated';
import TwitterBlack from './asset-components/TwitterBlack.generated';
import UploadBlack from './asset-components/UploadBlack.generated';
import UploadDisabled from './asset-components/UploadDisabled.generated';
import UploadWhite from './asset-components/UploadWhite.generated';
import VideoBlack from './asset-components/VideoBlack.generated';
import VideoDisabled from './asset-components/VideoDisabled.generated';
import VideoWhite from './asset-components/VideoWhite.generated';
import YoutubeBlack from './asset-components/YoutubeBlack.generated';

const componentMap = {
  'add-card | black': AddCardBlack,
  'add-card | disabled': AddCardDisabled,
  'add-card | white': AddCardWhite,
  'add | black': AddBlack,
  'add | disabled': AddDisabled,
  'add | small | black': AddSmallBlack,
  'add | small | disabled': AddSmallDisabled,
  'add | small | white': AddSmallWhite,
  'add | white': AddWhite,
  'align-center | black': AlignCenterBlack,
  'align-center | disabled': AlignCenterDisabled,
  'align-center | white': AlignCenterWhite,
  'align-left | black': AlignLeftBlack,
  'align-left | disabled': AlignLeftDisabled,
  'align-left | white': AlignLeftWhite,
  'align-right | black': AlignRightBlack,
  'align-right | disabled': AlignRightDisabled,
  'align-right | white': AlignRightWhite,
  'array-gallary | black': ArrayGallaryBlack,
  'array-gallary | disabled': ArrayGallaryDisabled,
  'array-gallary | white': ArrayGallaryWhite,
  'array-list | black': ArrayListBlack,
  'array-list | disabled': ArrayListDisabled,
  'array-list | white': ArrayListWhite,
  'arrow-right | black': ArrowRightBlack,
  'arrow-right | disabled': ArrowRightDisabled,
  'arrow-right | white': ArrowRightWhite,
  'bin | black': BinBlack,
  'bin | disabled': BinDisabled,
  'bin | small | black': BinSmallBlack,
  'bin | small | disabled': BinSmallDisabled,
  'bin | small | white': BinSmallWhite,
  'bin | white': BinWhite,
  'calendar | black': CalendarBlack,
  'calendar | disabled': CalendarDisabled,
  'calendar | small | black': CalendarSmallBlack,
  'calendar | small | disabled': CalendarSmallDisabled,
  'calendar | small | white': CalendarSmallWhite,
  'calendar | white': CalendarWhite,
  'cart | black': CartBlack,
  'cart | disabled': CartDisabled,
  'cart | white': CartWhite,
  'check-circle | black': CheckCircleBlack,
  'check-circle | disabled': CheckCircleDisabled,
  'check-circle | filled': CheckCircleFilled,
  'check-circle | filled | disabled': CheckCircleFilledDisabled,
  'check-circle | white': CheckCircleWhite,
  'check | black-with-white': CheckBlackWithWhite,
  'check | black': CheckBlack,
  'check | disabled': CheckDisabled,
  'check | small | black': CheckSmallBlack,
  'check | small | disabled': CheckSmallDisabled,
  'check | small | white': CheckSmallWhite,
  'check | white': CheckWhite,
  'checkbox | checked': CheckboxChecked,
  'checkbox | checked | disabled': CheckboxCheckedDisabled,
  'checkbox | unchecked': CheckboxUnchecked,
  'checkbox | unchecked | disabled': CheckboxUncheckedDisabled,
  'clock | black': ClockBlack,
  'clock | disabled': ClockDisabled,
  'clock | small | black': ClockSmallBlack,
  'clock | small | disabled': ClockSmallDisabled,
  'clock | small | white': ClockSmallWhite,
  'clock | white': ClockWhite,
  'close | black': CloseBlack,
  'close | disabled': CloseDisabled,
  'close | small | black': CloseSmallBlack,
  'close | small | disabled': CloseSmallDisabled,
  'close | small | white': CloseSmallWhite,
  'close | white': CloseWhite,
  'delete | black': DeleteBlack,
  'delete | disabled': DeleteDisabled,
  'delete | white': DeleteWhite,
  'edit-design | black': EditDesignBlack,
  'edit-design | disabled': EditDesignDisabled,
  'edit-design | white': EditDesignWhite,
  'edit | black': EditBlack,
  'edit | disabled': EditDisabled,
  'edit | small | black': EditSmallBlack,
  'edit | small | disabled': EditSmallDisabled,
  'edit | small | white': EditSmallWhite,
  'edit | white': EditWhite,
  'exclamation-mark-circle | filled | red': ExclamationMarkCircleFilledRed,
  'facebook | filled': FacebookFilled,
  'favorite | black': FavoriteBlack,
  'favorite | disabled': FavoriteDisabled,
  'favorite | filled': FavoriteFilled,
  'favorite | filled | disabled': FavoriteFilledDisabled,
  'favorite | large | black': FavoriteLargeBlack,
  'favorite | large | filled': FavoriteLargeFilled,
  'favorite | white': FavoriteWhite,
  'google | black': GoogleBlack,
  'google | disabled': GoogleDisabled,
  'google | filled': GoogleFilled,
  'google | white': GoogleWhite,
  'home | black': HomeBlack,
  'home | disabled': HomeDisabled,
  'home | white': HomeWhite,
  'image | black': ImageBlack,
  'image | disabled': ImageDisabled,
  'image | white': ImageWhite,
  'instagram | black': InstagramBlack,
  'kakao | black': KakaoBlack,
  'kakao | disabled': KakaoDisabled,
  'kakao | white': KakaoWhite,
  'like | black': LikeBlack,
  'like | disabled': LikeDisabled,
  'like | filled': LikeFilled,
  'like | filled | disabled': LikeFilledDisabled,
  'like | white': LikeWhite,
  'menu | black': MenuBlack,
  'menu | disabled': MenuDisabled,
  'menu | white': MenuWhite,
  'move | black': MoveBlack,
  'move | disabled': MoveDisabled,
  'move | white': MoveWhite,
  'music | black': MusicBlack,
  'music | disabled': MusicDisabled,
  'music | white': MusicWhite,
  'naver | black': NaverBlack,
  'naver | disabled': NaverDisabled,
  'naver | white': NaverWhite,
  'naverblog | black': NaverblogBlack,
  'notification-ringing | black': NotificationRingingBlack,
  'notification-ringing | disabled': NotificationRingingDisabled,
  'notification-ringing | white': NotificationRingingWhite,
  'notification | black': NotificationBlack,
  'notification | disabled': NotificationDisabled,
  'notification | white': NotificationWhite,
  'personal-information | black': PersonalInformationBlack,
  'personal-information | disabled': PersonalInformationDisabled,
  'personal-information | white': PersonalInformationWhite,
  'phone | black': PhoneBlack,
  'phone | disabled': PhoneDisabled,
  'phone | white': PhoneWhite,
  'power | black': PowerBlack,
  'power | disabled': PowerDisabled,
  'power | white': PowerWhite,
  'privacy-policy | black': PrivacyPolicyBlack,
  'privacy-policy | disabled': PrivacyPolicyDisabled,
  'privacy-policy | white': PrivacyPolicyWhite,
  'profile | black': ProfileBlack,
  'profile | disabled': ProfileDisabled,
  'profile | white': ProfileWhite,
  'radio | checked': RadioChecked,
  'radio | checked | disabled': RadioCheckedDisabled,
  'radio | unchecked': RadioUnchecked,
  'radio | unchecked | disabled': RadioUncheckedDisabled,
  'ratio-landscape-m | black': RatioLandscapeMBlack,
  'ratio-landscape-s | black': RatioLandscapeSBlack,
  'ratio-portrait | black': RatioPortraitBlack,
  'ratio-square | black': RatioSquareBlack,
  'reply | black': ReplyBlack,
  'reply | disabled': ReplyDisabled,
  'reply | white': ReplyWhite,
  'search | black': SearchBlack,
  'search | disabled': SearchDisabled,
  'search | white': SearchWhite,
  'settings | black': SettingsBlack,
  'settings | disabled': SettingsDisabled,
  'settings | white': SettingsWhite,
  'share | black': ShareBlack,
  'share | disabled': ShareDisabled,
  'share | white': ShareWhite,
  'shop | black': ShopBlack,
  'shop | disabled': ShopDisabled,
  'shop | white': ShopWhite,
  'small-arrow-down | black': SmallArrowDownBlack,
  'small-arrow-down | disabled': SmallArrowDownDisabled,
  'small-arrow-down | small | black': SmallArrowDownSmallBlack,
  'small-arrow-down | small | white': SmallArrowDownSmallWhite,
  'small-arrow-down | white': SmallArrowDownWhite,
  'small-arrow-right | black': SmallArrowRightBlack,
  'small-arrow-right | disabled': SmallArrowRightDisabled,
  'small-arrow-right | white': SmallArrowRightWhite,
  'small-arrow-up | black': SmallArrowUpBlack,
  'small-arrow-up | disabled': SmallArrowUpDisabled,
  'small-arrow-up | white': SmallArrowUpWhite,
  'social-media | black': SocialMediaBlack,
  'sort | black': SortBlack,
  'sort | disabled': SortDisabled,
  'sort | white': SortWhite,
  'subtract | black': SubtractBlack,
  'subtract | disabled': SubtractDisabled,
  'subtract | small | black': SubtractSmallBlack,
  'subtract | small | disabled': SubtractSmallDisabled,
  'subtract | small | white': SubtractSmallWhite,
  'subtract | white': SubtractWhite,
  'text-margin | bottom-n-top': TextMarginBottomNTop,
  'text-margin | bottom': TextMarginBottom,
  'text-margin | default': TextMarginDefault,
  'text-margin | top': TextMarginTop,
  'tiktok | black': TiktokBlack,
  'twitter | black': TwitterBlack,
  'upload | black': UploadBlack,
  'upload | disabled': UploadDisabled,
  'upload | white': UploadWhite,
  'video | black': VideoBlack,
  'video | disabled': VideoDisabled,
  'video | white': VideoWhite,
  'youtube | black': YoutubeBlack,
};

export default componentMap;
