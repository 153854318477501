import Image from 'next/image';

import SmallModal from '@design0.2/Modal/SmallModal';
import { useModalToast } from '@design0.2/Toast/hooks/useToast';
import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import useInAppBrowserWarningModal from '@shared/hooks/useInAppBrowserWarningModal';
import appIconChrome from '@static/media/app_icon_chrome.png';
import appIconSafari from '@static/media/app_icon_safari.png';
import { styled } from '@styles/stitches.config';
import { copyToClipboard } from '@utils/dom';

const InAppBrowserWarningModal = () => {
  const inAppBrowserWarningModal = useInAppBrowserWarningModal();
  const toast = useModalToast();

  const onClickCopy = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.LANDING_TOAST.GOOGLE_SIGNIN_TOAST_CLICK_CLIPBOARD
    );
    copyToClipboard(location.href);
    toast.good(i18n.t('k_the_address_has_been_saved_to_the_clipboard'));
  };

  return (
    <SmallModal onClose={inAppBrowserWarningModal.close} closeButton>
      <Root>
        <PrimaryMessage>
          {i18n.t('k_web_view_guide_message_head')}
        </PrimaryMessage>
        <span onClick={onClickCopy}>
          {'👆' + i18n.t('k_copy_to_clipboard') + '👆'}
        </span>
        <BrowserSection>
          <Browser>
            <Image
              src={appIconChrome}
              width={96}
              height={96}
              objectFit={'contain'}
              alt={'app-icon'}
            />
            <BrowserName>{i18n.t('k_chrome')}</BrowserName>
          </Browser>
          <Browser>
            <Image
              src={appIconSafari}
              width={96}
              height={96}
              objectFit={'contain'}
              alt={'app-icon'}
            />
            <BrowserName>{i18n.t('k_safari')}</BrowserName>
          </Browser>
        </BrowserSection>
        <SecondaryMessage>
          {i18n.t('k_web_view_guide_message_caption')}
        </SecondaryMessage>
      </Root>
    </SmallModal>
  );
};

const Root = styled('div', {
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
});

const PrimaryMessage = styled('p', {
  fontType: 'bodyBold',
  textAlign: 'center',
});

const BrowserSection = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 200,
  borderRadius: 16,
  backgroundColor: '#faf8ff',
});

const Browser = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const BrowserName = styled('span', {
  fontType: 'bodyBold',
});

const SecondaryMessage = styled('p', {
  fontType: 'captionRegular',
});

export default InAppBrowserWarningModal;
