import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { TempCreateOrderLineItemInput } from '@schema/types';

export type SignInAndUpModalStoreState = {
  opened: boolean;
  purpose: 'normal' | 'order' | 'checkout';
  type: 'signIn' | 'kemiSignIn' | 'signUp' | 'termsDetail';
  linkName: string | null;
  redirectTo: string | null;
  selectedTerms: {
    service: boolean;
    privacy: boolean;
    marketing: boolean;
  };
  isTermsSelectionDirty: boolean;
  shouldSelectRequiredTermsFlag: boolean;
  kemiId: string;
  kemiPassword: string;
  hasKemiLoginError: boolean;
  orderLineItemsToOrder: Array<TempCreateOrderLineItemInput> | null;
};

const INITIAL_STATE: SignInAndUpModalStoreState = {
  opened: false,
  purpose: 'normal',
  type: 'signIn',
  linkName: null,
  redirectTo: null,
  selectedTerms: {
    service: false,
    privacy: false,
    marketing: false,
  },
  isTermsSelectionDirty: false,
  shouldSelectRequiredTermsFlag: false,
  kemiId: '',
  kemiPassword: '',
  hasKemiLoginError: false,
  orderLineItemsToOrder: null,
};

export type SignInAndUpModalStoreActions = {
  open: (
    options:
      | {
          purpose: 'normal';
          type: SignInAndUpModalStoreState['type'];
          redirectTo?: string;
          linkName?: string;
        }
      | {
          purpose: 'order';
          orderLineItemsToOrder: Array<TempCreateOrderLineItemInput>;
        }
      | {
          purpose: 'checkout';
        }
  ) => void;
  close: () => void;
  changeMode: (mode: SignInAndUpModalStoreState['type']) => void;
  updateTerms: (
    terms: Partial<SignInAndUpModalStoreState['selectedTerms']>
  ) => void;
  selectAllTerms: () => void;
  deselectAllTerms: () => void;
  setShouldSelectRequiredTermsFlag: (
    shouldSelectRequiredTermsFlag: boolean
  ) => void;
  setKemiId: (kemiId: string) => void;
  setkemiPassword: (kemiPassword: string) => void;
  setHasKemiLoginError: (hasKemiLoginError: boolean) => void;
};

const useSignInAndUpModalStore = create<
  SignInAndUpModalStoreState & { actions: SignInAndUpModalStoreActions }
>()(
  devtools(
    (set, get) => ({
      ...INITIAL_STATE,
      actions: {
        open: (option) => {
          set({ opened: true });

          switch (option.purpose) {
            case 'normal':
              set({
                ...option,
              });
              break;
            case 'order':
              set({
                ...option,
                type: 'signUp',
              });
              break;
            case 'checkout':
              set({
                ...option,
                type: 'signIn',
              });
          }
        },
        close: () => set(INITIAL_STATE),
        changeMode: (mode) => set({ type: mode }),
        updateTerms: (terms) =>
          set({
            selectedTerms: {
              ...get().selectedTerms,
              ...terms,
            },
            isTermsSelectionDirty: true,
            shouldSelectRequiredTermsFlag: false,
          }),
        selectAllTerms: () =>
          set({
            selectedTerms: {
              service: true,
              privacy: true,
              marketing: true,
            },
            isTermsSelectionDirty: true,
            shouldSelectRequiredTermsFlag: false,
          }),
        deselectAllTerms: () =>
          set({
            selectedTerms: {
              service: false,
              privacy: false,
              marketing: false,
            },
            isTermsSelectionDirty: true,
            shouldSelectRequiredTermsFlag: false,
          }),
        setShouldSelectRequiredTermsFlag: (
          shouldSelectRequiredTermsFlag: boolean
        ) => set({ shouldSelectRequiredTermsFlag }),
        setKemiId: (kemiId) => set({ kemiId, hasKemiLoginError: false }),
        setkemiPassword: (kemiPassword) =>
          set({ kemiPassword, hasKemiLoginError: false }),
        setHasKemiLoginError: (hasKemiLoginError) => set({ hasKemiLoginError }),
      },
    }),
    { name: 'signInAndUpModalStore' }
  )
);

export default useSignInAndUpModalStore;
