import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import React, { Suspense } from 'react';

import { applyServerSideLanguage } from '../../i18n';

import Authorization from './app/Authorization';
import useRouteChange from './app/hooks/useRouteChange';
import KemiHead from './app/KemiHead';
import Wrapper from './app/Wrapper';

import FullPageLoader from '@global/components/templates/FullPageLoader/FullPageLoader';
import KemiLayout from '@pages/app/components/KemiLayout';
import KemiPlaygroundLayout from '@pages/app/components/KemiPlaygroundLayout';
import { useConfigAppProperties } from '@pages/app/hooks/useConfigAppProperties';
import ErrorBoundary from '@shared/components/ErrorBoundary';
import KemiAppContext from '@shared/contexts/KemiAppContext';
import { ServerSideProvider } from '@shared/contexts/ServerSideContext';
import useKemiQueryClient from '@shared/hooks/useKemiQueryClient';
import { KemiNextPage } from '@shared/types/next';
import { resetCss, globalStyles } from '@styles/globalStyles';
import ChannelTalk from 'src/shared/components/ChannelTalk';
import ThirdPartyScripts from 'src/shared/components/ThirdPartyScripts';

type CustomPageProps = {
  noLayout?: boolean;
  dehydratedState: unknown;
  userAgent?: string;
  linkName?: string;
};

type NextPageLayoutProps = {
  Component: KemiNextPage<CustomPageProps>;
};

type MyAppProps = AppProps<CustomPageProps> & NextPageLayoutProps;

function MyApp(props: MyAppProps) {
  const { pageProps, Component } = props;
  const { userAgent, dehydratedState } = pageProps;
  const { layoutType = 'NONE' } = Component;

  const queryClient = useKemiQueryClient();

  const noLayout = layoutType === 'NONE';

  useConfigAppProperties();
  useRouteChange();
  resetCss();
  globalStyles();
  return (
    <ServerSideProvider value={{ userAgent }}>
      <KemiAppContext.Provider value={{ layoutType }}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <Hydrate state={dehydratedState}>
            <ErrorBoundary>
              <ChannelTalk>
                <KemiHead />
                <ThirdPartyScripts />
                <Wrapper noLayout={noLayout}>
                  <Authorization>
                    <Suspense fallback={<FullPageLoader />}>
                      {getLayout(props)}
                    </Suspense>
                  </Authorization>
                </Wrapper>
              </ChannelTalk>
            </ErrorBoundary>
          </Hydrate>
        </QueryClientProvider>
      </KemiAppContext.Provider>
    </ServerSideProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appValue = await App.getInitialProps(appContext);

  const req = appContext.ctx.req;

  const isOnServer = typeof req !== 'undefined';

  if (isOnServer) {
    applyServerSideLanguage(req);
  }

  return { ...appValue };
};

const getLayout = ({ Component, pageProps }: MyAppProps) => {
  const children = <Component {...pageProps} />;

  switch (Component.layoutType) {
    case 'KEMI':
      return <KemiLayout linkName={pageProps.linkName}>{children}</KemiLayout>;
    case 'KEMI_PLAYGROUND_DESKTOP_CONTENT_VIEW_TYPE_IS_WIDE':
      return (
        <KemiPlaygroundLayout desktopContentMode={'DESKTOP'}>
          {children}
        </KemiPlaygroundLayout>
      );
    case 'KEMI_PLAYGROUND_DESKTOP_CONTENT_VIEW_TYPE_IS_MOBILE':
      return (
        <KemiPlaygroundLayout desktopContentMode={'MOBILE'}>
          {children}
        </KemiPlaygroundLayout>
      );
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{children}</>;
  }
};

export default MyApp;
