import Image from 'next/image';

import Button from '@design0.2/Button/Button';
import i18n from '@i18n/index';
import networkKemi from '@static/media/kemi-network.svg';
import { styled } from '@styles/stitches.config';

function NetworkError() {
  return (
    <Container>
      <ImageContainer>
        <Image src={networkKemi} alt={''} />
      </ImageContainer>
      <Title>{i18n.t('k_network_status_bad')}</Title>
      <Description>
        {i18n.t('k_try_again_after_check_network_connect_status')}
      </Description>
      <Button buttonStyle={'secondary'} onClick={() => location.reload()}>
        {i18n.t('k_retry')}
      </Button>
      <LinkDescription
        target={'_blank'}
        rel={'noopener noreferer noreferrer'}
        href={'https://kemi.channel.io/lounge'}
      >
        {i18n.t('k_network_problem_is_not_solved')}
      </LinkDescription>
    </Container>
  );
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: 16,
  textAlign: 'center',
  backgroundColor: '$primary10',
});

const ImageContainer = styled('div', {
  marginBottom: 24,
});

const Title = styled('span', {
  fontType: 'bodyBold',
  marginBottom: 8,
});

const Description = styled('span', {
  fontType: 'bodyRegular',
  whiteSpace: 'pre-line',
  marginBottom: 24,
});

const LinkDescription = styled('a', {
  fontType: 'captionRegular',
  color: '$grey100',
  marginTop: 24,
  cursor: 'pointer',
});

export default NetworkError;
