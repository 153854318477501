import Cookies from 'js-cookie';
import Script from 'next/script';

import useEffectOnce from '@global/hooks/useEffectOnce';
import globalErrorHandler from '@global/service/Error/globalErrorHandler';
import { LIVE_TRANSLATION_MODE_KEY } from '@i18n/index';

const IS_NOT_PROD = process.env.VERCEL_ENV !== 'prod';

const LIVE_TRANSLATION_MODE = (() => {
  if (typeof window === 'undefined') {
    return false;
  }

  return !!Cookies.get(LIVE_TRANSLATION_MODE_KEY);
})();

const ThirdPartyScripts = () => {
  /** 카카오 */
  useEffectOnce(() => {
    const loadKakao = async () => {
      try {
        await import('../third-party/kakao');
        Kakao.init(process.env.NEXT_PUBLIC_KAKAO_APP_KEY as string);
      } catch (e) {
        globalErrorHandler(e);
      }
    };

    loadKakao();
  });

  return (
    <>
      {/* 크라우딘 */}
      {IS_NOT_PROD && LIVE_TRANSLATION_MODE && (
        <>
          <Script
            dangerouslySetInnerHTML={{
              __html: `
                var _jipt = [];
                _jipt.push(['project', 'kemi-v2']);
              `,
            }}
          />
          <Script src={'//cdn.crowdin.com/jipt/jipt.js'} async />
        </>
      )}
    </>
  );
};

export default ThirdPartyScripts;
