import KemiSignIn from './KemiSignIn';
import SignIn from './SignIn';
import SignUp from './SignUp';
import TermsSelection from './TermsSelection';

import SmallModal from '@design0.2/Modal/SmallModal';
import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';

const SignInAndUpModal = () => {
  const signInAndUpModal = useSignInAndUpModal();

  const close = () => {
    signInAndUpModal.close();

    if (signInAndUpModal.type === 'signUp') {
      logFirebase(
        UserInteractionType.NONE,
        EVENT_TAG.SIGN_IN_AND_UP.SIGNUP_POPUP_CLOSE_CLICK
      );
    } else if (signInAndUpModal.type === 'signIn') {
      logFirebase(
        UserInteractionType.NONE,
        EVENT_TAG.SIGN_IN_AND_UP.SIGNUP_POPUP_SIGNIN_CLOSE_CLICK
      );
    } else if (signInAndUpModal.type === 'termsDetail') {
      logFirebase(
        UserInteractionType.NONE,
        EVENT_TAG.SIGN_IN_AND_UP.TERMS_N_CONDITIONS_CLOSE_CLICK
      );
    }
  };

  const closeButtonVisible = signInAndUpModal.type !== 'termsDetail';

  return (
    <SmallModal onClose={close} closeButton={closeButtonVisible}>
      {(() => {
        switch (signInAndUpModal.type) {
          case 'signUp':
            return <SignUp />;
          case 'termsDetail':
            return <TermsSelection />;
          case 'kemiSignIn':
            return <KemiSignIn />;
          case 'signIn':
          default:
            return <SignIn />;
        }
      })()}
    </SmallModal>
  );
};
export default SignInAndUpModal;
