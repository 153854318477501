import { useEffect } from 'react';

import OAuthProvider from './OAuthProvider';

import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import ROUTE from '@routers';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';
import { styled } from '@styles/stitches.config';
import I18n from 'src/i18n/components/I18n';

const SignIn = () => {
  const signInAndUpModal = useSignInAndUpModal();

  useEffect(() => {
    logFirebase(
      UserInteractionType.NONE,
      EVENT_TAG.SIGN_IN_AND_UP.SCREEN_OPEN_SIGNIN_POPUP
    );
  }, []);

  return (
    <div>
      <Title>{i18n.t('k_login_to_kemi')}</Title>
      <SignInProvidersSection>
        <OAuthProvider
          provider={'kakao'}
          type={'signIn'}
          onClick={() => signInAndUpModal.proceedWithOAuth('kakao')}
        />
        <OAuthProvider
          provider={'naver'}
          type={'signIn'}
          onClick={() => signInAndUpModal.proceedWithOAuth('naver')}
        />
        <OAuthProvider
          provider={'google'}
          type={'signIn'}
          onClick={() => signInAndUpModal.proceedWithOAuth('google')}
        />
        <OAuthProvider
          provider={'facebook'}
          type={'signIn'}
          onClick={() => signInAndUpModal.proceedWithOAuth('facebook')}
        />
        <SignInTermsMessage>
          <I18n
            i18nKey={'k_signin_modal_message'}
            i18nOptions={{
              a1attr: `href="${ROUTE.privacy}" target="_blank"`,
              a2attr: `href="${ROUTE.terms}" target="_blank"`,
            }}
          />
        </SignInTermsMessage>
      </SignInProvidersSection>
      <ToKemiSignIn onClick={() => signInAndUpModal.changeMode('kemiSignIn')}>
        {i18n.t('k_sign_in_with_kemi')}
      </ToKemiSignIn>
      <SignUpSection>
        <SignUpMessage>{i18n.t('k_new_to_kemi')}</SignUpMessage>
        <ToSignUp onClick={() => signInAndUpModal.changeMode('signUp')}>
          {i18n.t('k_sign_up_2')}
        </ToSignUp>
      </SignUpSection>
    </div>
  );
};

const Title = styled('div', {
  fontType: 'heading3',
  color: '$black',
  padding: 16,
});

const SignInProvidersSection = styled('div', {
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const SignInTermsMessage = styled('div', {
  textAlign: 'center',
  fontType: 'labelRegular',
  color: '$grey100',
});

const SignUpSection = styled('div', {
  padding: 16,
  display: 'flex',
  alignItems: 'center',
  gap: 16,
});

const SignUpMessage = styled('span', {
  fontType: 'bodyRegular',
  color: '$black',
});

const ToSignUp = styled('div', {
  fontType: 'bodyBold',
  color: '$black',
  cursor: 'pointer',
});

const ToKemiSignIn = styled('div', {
  padding: 16,
  fontType: 'bodyRegular',
  color: '$grey100',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default SignIn;
