import { useMemo } from 'react';

import Icon from '@design0.2/Icon/Icon';
import i18n from '@i18n/index';
import { styled } from '@styles/stitches.config';
import { OAuthProviderType } from 'src/auth/types';

type OAuthProviderProps = {
  provider: OAuthProviderType;
  type: 'signUp' | 'signIn';
  onClick: () => void;
};

const OAuthProvider = (props: OAuthProviderProps) => {
  const { provider, type, onClick } = props;

  const icon = useMemo(() => {
    switch (provider) {
      case 'kakao':
        return <Icon type={'kakao | black'} />;
      case 'naver':
        return <Icon type={'naver | white'} />;
      case 'google':
        return <Icon type={'google | filled'} />;
      case 'facebook':
        return <Icon type={'facebook | filled'} />;
      default:
        return null;
    }
  }, [provider]);

  const text = useMemo(() => {
    const oAuthProvider = i18n.t(`k_${provider}`);

    switch (type) {
      case 'signUp':
        return i18n.t('k_sign_up_with_oauth_provider', { oAuthProvider });
      case 'signIn':
        return i18n.t('k_sign_in_with_oauth_provider', { oAuthProvider });
      default:
        return null;
    }
  }, [provider, type]);

  return (
    <Itself provider={provider} onClick={onClick}>
      {icon}
      {text}
    </Itself>
  );
};

const Itself = styled('div', {
  padding: '11px 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
  borderRadius: 8,
  fontType: 'subtitleBold',
  cursor: 'pointer',
  color: '$black',

  variants: {
    provider: {
      kakao: {
        backgroundColor: '#FAE64D',
        border: '1px solod #FAE64D',
      },
      naver: {
        backgroundColor: '#5AC466',
        border: '1px solid #5AC466',
        color: '$white',
      },
      google: {
        border: '1px solid $grey50',
      },
      facebook: {
        border: '1px solid $grey50',
      },
    },
  },
});

export default OAuthProvider;
