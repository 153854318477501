import acceptLanguageParser from 'accept-language';
import cookie from 'cookie';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import { NextPageContext } from 'next';
import { initReactI18next } from 'react-i18next';

import translationEn from './en/strings.json';
import translationId from './id/strings.json';
import translationKo from './ko/strings.json';
import translationPseudo from './pseudo/strings.json';

import { I18nKey } from '@i18n/types.generated';

const KOREAN = 'ko';
const INDONESIAN = 'id';
const ENGLISH = 'en';

const I18N_DEFAULT_LANGUAGE = ENGLISH;

// 크라우딘 in-context 모드를 위해 쓰이는 임의로 지정한 언어코드 (크라우딘 콘솔에서 ach로 설정함)
const LIVE_TRANSLATION_LANGUAGE = 'ach';
// 크라우딘 in-context 모드 전환에 쓰이는 스위치 쿠키 키
export const LIVE_TRANSLATION_MODE_KEY = 'live-translation-mode';

const resource = {
  [ENGLISH]: {
    translation: translationEn,
  },
  [INDONESIAN]: {
    translation: translationId,
  },
  [KOREAN]: {
    translation: translationKo,
  },
  [LIVE_TRANSLATION_LANGUAGE]: {
    translation: translationPseudo,
  },
};

const IS_PROD = process.env.VERCEL_ENV === 'prod';

/**
 * i18next 초기화
 *
 * 고민:
 * prerendering인 케이스(getServerSideProps를 타지않는 페이지)는 영어로 그려질텐데,
 * 한국어 브라우저에서 하이드레이션 이후에는 한국어로 나오는데 괜찮은건가
 */
const initI18next = () => {
  let language = I18N_DEFAULT_LANGUAGE;

  const isLiveTranslationMode =
    !IS_PROD &&
    typeof window !== 'undefined' &&
    !!Cookies.get(LIVE_TRANSLATION_MODE_KEY);

  if (isLiveTranslationMode) {
    // 크라우딘 in-context 모드
    language = LIVE_TRANSLATION_LANGUAGE;
  } else if (typeof window !== 'undefined') {
    // 클라이언트 환경
    // 고민: 클라이언트 환경인데 navigator.languages가 비어있는 케이스가 있을까?

    // navigator에 있는 languages 값을 accept-language 헤더 값에 호환되도록 변환
    const acceptLanguageHeader = navigator.languages.join(',');
    language = negotiateLanguage(acceptLanguageHeader);

    /**
     * 네이버 수집봇 중에 클라이언트렌더링을 하는 네이버 수집봇은 accept-language가 en만 있어서 한국어로 고정한다.
     * 봇이름은 Blueno (https://help.naver.com/service/5626/contents/19008?lang=ko)
     */
    if (navigator.userAgent.includes('Blueno')) {
      language = KOREAN;
    }
  }

  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: resource,
      lng: language,
      fallbackLng: I18N_DEFAULT_LANGUAGE,
      debug: false,
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        prefix: '{',
        suffix: '}',
        escapeValue: false, // react already safes from xss
      },
    });
};

/**
 * ServerSide 환경에서 적용할 언어를 설정한다.
 * ServerSide 환경에서 유저가 원하는 언어에 맞춰 렌더링 하기 위해 http request의 accept-language 헤더 값을 참조해 SSR에 쓸 언어를 결정한다.
 *
 * nextjs가 구동된 이후에 실행되는 함수이기에 initI18next함수 실행 이후에 실행되어야한다.
 */
export const applyServerSideLanguage = (
  req: NonNullable<NextPageContext['req']>
) => {
  const { headers } = req;

  const acceptLanguage = headers['accept-language'];

  const cookies = cookie.parse(headers.cookie ?? '');

  const isLiveTranslationMode = !IS_PROD && cookies[LIVE_TRANSLATION_MODE_KEY];

  let language = '';

  if (isLiveTranslationMode) {
    // 크라우딘 in-context 모드
    language = LIVE_TRANSLATION_LANGUAGE;
  } else if (!acceptLanguage) {
    // accept-language 헤더가 없는 경우

    /**
     * 일부 요청(예: 일부 OG 봇)은 accept-language가 없는 채로 찌르는 경우가 있는데, 이때 한국어로 고정한다.
     * 비록 영어가 기본값이지만, OG 데이터가 실제로 쓰이는 케이스는 한국이 99.9%일것이기 때문이다.
     */
    language = KOREAN;
  } else {
    // 일반적인 케이스
    language = negotiateLanguage(acceptLanguage);
  }

  i18next.changeLanguage(language);
};

/**
 * 우리 앱이 지원하는 언어와 페이지 요청에 포함된 accept-language를 조합해 적합한 언어를 결정한다.
 *
 * @param acceptLanguageHeader accept-language 헤더 값 포멧에 준수하는 값
 * @returns 우리 앱이 지원하는 언어와 accept-language를 조합해 선택된 언어
 */
const negotiateLanguage = (acceptLanguageHeader: string) => {
  // 0번째 인덱스는 기본값. 만약 accept-language에 우리가 지원하는 언어가 아무것도 없다면 en으로 인식시킨다.
  // 만약에 언어설정이 스페인어가 1순위인 유저(2순위는 영어)가 webkit으로 들어온다면 (스페인어만 accept-language로 들어올꺼라서 영어가 기본값이어야함)
  acceptLanguageParser.languages([ENGLISH, KOREAN, INDONESIAN]);
  return (
    acceptLanguageParser.get(acceptLanguageHeader) ?? I18N_DEFAULT_LANGUAGE
  );
};

initI18next();

const i18n = {
  t: (key: I18nKey, interpolation?: { [k in string]: any }) => {
    return i18next.t(key, interpolation);
  },
};
export default i18n;
