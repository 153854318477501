import { nanoid } from 'nanoid';
import { MouseEvent, useEffect, useState } from 'react';

import Box from '../Box';
import Text from '../Text';

import { EVENT_TAG, KEMI_ROOT_TAG_ID } from '@global/constants';
import KemiApiError from '@global/service/Error/KemiApiError';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import CheckCircle from '@static/svg/CheckCircle';
import ExclamationMark from '@static/svg/ExclamationMark';
import { styled } from '@styles/stitches.config';
import { extract } from '@utils/string';

const TOAST_TRANSITION_COUNT = 0.3;

type ToastTypes = {
  id: string;
  content: string | JSX.Element;
};

class ToastStore {
  toasts: ToastTypes[];
  subscribers: any[];

  constructor() {
    this.toasts = [];
    this.subscribers = [];
  }

  dispatch(toast: ToastTypes) {
    this.toasts = this.toasts.concat([toast]);
    this.notify();
  }

  remove(id: string) {
    this.toasts = this.toasts.filter((toast) => toast.id !== id);
    this.notify();
  }

  notify() {
    this.subscribers.forEach((subscriber) => {
      subscriber(this.toasts);
    });
  }

  subscribe(callback: any) {
    this.subscribers = this.subscribers.concat([callback]);
  }

  unsubscribe(callback: any) {
    this.subscribers = this.subscribers.filter(
      (subscriber) => subscriber !== callback
    );
  }
}

const store = new ToastStore();
const ToastContainer = () => {
  const [toasts, setToasts] = useState<ToastTypes[]>([]);
  const [bottom, setBottom] = useState<number>(0);
  const { TRANSITION, TOAST_LIMIT } = getTransitionRate();
  const initialInnerHeight =
    typeof window !== 'undefined' && window.innerHeight;

  const removeToast = (id: string) => {
    store.remove(id);
  };

  const computeBottom = () => {
    if (document) {
      const bodyRef = document.body;
      const kemiRootRef = document?.querySelector(`#${KEMI_ROOT_TAG_ID}`);

      if (!bodyRef || !kemiRootRef) {
        setBottom(0);
        return;
      }

      setBottom(
        bodyRef.getBoundingClientRect()?.bottom -
          kemiRootRef.getBoundingClientRect()?.bottom
      );
    }
  };

  const handleToastItemClick = (
    e: MouseEvent<HTMLDivElement>,
    toastId: string
  ) => {
    e.currentTarget.classList.remove('visible');
    setTimeout(() => {
      removeToast(toastId);
    }, TRANSITION);
  };

  const toastCallbackRef = (el: HTMLDivElement | null) => {
    if (!el) return;

    if (el.classList.contains('visible')) return;

    setTimeout(() => {
      el.classList.add('visible');
      setTimeout(() => {
        el.classList.remove('visible');
      }, TOAST_LIMIT - TRANSITION);
    }, 0);
  };

  useEffect(() => {
    const callback = (toasts: ToastTypes[]) => {
      setToasts(toasts);
    };

    store.subscribe(callback);

    return () => {
      store.unsubscribe(callback);
    };
  }, []);

  useEffect(() => {
    computeBottom();
    (() => {
      addThrottleEvent('resize', new CustomEvent('optimizedResize'));
    })();

    const listener = function () {
      if (initialInnerHeight !== window.innerHeight) {
        toasts.map((toast) => {
          const _toast = document.querySelector(`#toast-${toast.id}`);
          _toast?.classList.add('invisible');
          _toast?.classList.remove('visible');
          removeToast(toast.id);

          setTimeout(() => {
            removeToast(toast.id);
          }, TRANSITION);
        });
      }

      computeBottom();
    };

    window.addEventListener('optimizedResize', listener);

    return () => {
      window.removeEventListener('optimizedResize', listener);
    };
  }, [TRANSITION, initialInnerHeight, toasts]);

  return (
    <Container style={{ bottom: bottom }}>
      {toasts.map((toast) => {
        return (
          <Toast
            key={toast.id}
            id={`toast-${toast.id}`}
            onClick={(e) => handleToastItemClick(e, toast.id)}
            ref={toastCallbackRef}
          >
            <ToastText color={'white'} font={'bodyRegular'}>
              {toast.content}
            </ToastText>
          </Toast>
        );
      })}
    </Container>
  );
};

const popToast = (content: string | JSX.Element) => {
  const id = nanoid();
  const { TOAST_LIMIT } = getTransitionRate();

  store.dispatch({ id, content });

  setTimeout(() => {
    store.remove(id);
  }, TOAST_LIMIT);
};

type ToastOption = {
  noOverlap?: boolean;
};

export const toast = {
  bad: (message: string | JSX.Element, option?: ToastOption) => {
    if (option && option.noOverlap) {
      const hasSameToast = store.toasts.some(
        (toast) =>
          (toast.content as JSX.Element).props.children[1].props.children ===
          message
      );

      if (hasSameToast) return;
    }

    logFirebase(UserInteractionType.TOAST, EVENT_TAG.TOAST_ERROR.TOAST_ERROR, {
      url: window.location.href,
      content: typeof message === 'string' && message,
    });

    popToast(
      <>
        <ExclamationMarkIcon color={'white'} />
        <Text font={'bodyRegular'} color={'white'}>
          {message}
        </Text>
      </>
    );
  },
  good: (message: string | JSX.Element, option?: ToastOption) => {
    if (option && option.noOverlap) {
      const hasSameToast = store.toasts.some(
        (toast) =>
          (toast.content as JSX.Element).props.children[1].props.children ===
          message
      );

      if (hasSameToast) return;
    }

    popToast(
      <>
        <CheckCircleIcon />
        <Text font={'bodyRegular'} color={'white'}>
          {message}
        </Text>
      </>
    );
  },
};

export const toastUtils = {
  invalidWord: (error: KemiApiError) => {
    const word = extract(error.message, { prefix: '[', suffix: ']' });
    return i18n.t('k_contains_invalid_words', {
      invalid_word: word,
    });
  },
};

const getTransitionRate = () => {
  const TRANSITION = TOAST_TRANSITION_COUNT * 1000;
  const TOAST_LIMIT = TRANSITION + 2700;

  return { TRANSITION, TOAST_LIMIT };
};

export default ToastContainer;

const addThrottleEvent = function (
  type: string,
  customEvent: CustomEvent,
  element?: HTMLElement | Window
) {
  element = element || window;
  let running = false;
  const func = function () {
    if (running) {
      return;
    }

    running = true;
    requestAnimationFrame(function () {
      element?.dispatchEvent(customEvent);
      running = false;
    });
  };
  element.addEventListener(type, func);
};

const Container = styled(Box, {
  position: 'fixed',
  zIndex: '$toast',
  bottom: 0,
  width: '$appWidthWhenDesktop',
  paddingBottom: 16,
  overflow: 'hidden',
  transition: 'height 0.3s',
  gap: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',

  '@mobile': {
    width: '100%',
  },
});

const Toast = styled('div', {
  width: 'calc(100% - 32px)',
  minHeight: 48,
  margin: '0 16px 0 16px',
  padding: 12,
  transition: 'opacity 0.3s',
  borderRadius: 8,
  opacity: 0,
  transparentColor: 'transparentBlack',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '&:hover': {
    cursor: 'pointer',
  },

  '&.visible': {
    opacity: 1,
  },

  '&.invisible': {
    display: 'none',
  },
});

const CheckCircleIcon = styled(CheckCircle, {
  marginRight: 8,
});

const ExclamationMarkIcon = styled(ExclamationMark, {
  marginRight: 8,
});

const ToastText = styled(Text, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});
