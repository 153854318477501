import * as Dialog from '@radix-ui/react-dialog';
import { ReactNode } from 'react';

import Icon from '@design0.2/Icon/Icon';
import { styled } from '@styles/stitches.config';

type SmallModalProps = {
  title?: ReactNode;
  closeButton?: boolean;
  onClose: () => void;
  // children 강제
  children: ReactNode;
};

const SmallModal = (props: SmallModalProps) => {
  const { title, closeButton, onClose, children } = props;

  const needHeader = title || closeButton;

  return (
    <Dialog.Root defaultOpen={true} onOpenChange={(open) => !open && onClose()}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent
            // radix에서 해주는 focus로 인한 outline 효과를 없애기 위한 로직
            onOpenAutoFocus={(e) => e.preventDefault()}
          >
            {needHeader && (
              <ContentHeader>
                <ContentHeaderTitle hidden={!title}>{title}</ContentHeaderTitle>
                <ContentHeaderCloseButton
                  onClick={onClose}
                  hidden={!closeButton}
                >
                  <Icon type={'close | black'} />
                </ContentHeaderCloseButton>
              </ContentHeader>
            )}
            <ContentBody>{children}</ContentBody>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const DialogOverlay = styled(Dialog.Overlay, {
  position: 'fixed',
  transparentColor: 'translucentBlack',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: '$modal',
  padding: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const DialogContent = styled(Dialog.Content, {
  width: '100%',
  maxWidth: 480,
  maxHeight: '100%',
  backgroundColor: '$white',
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'hidden',
});

const ContentHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: 16,
  gap: 16,
});

const Hidden = styled('span', {
  variants: {
    hidden: {
      true: {
        visibility: 'hidden',
      },
    },
  },
});

const ContentHeaderTitle = styled(Hidden, { fontType: 'titleBold' });

const ContentHeaderCloseButton = styled(Hidden, { cursor: 'pointer' });

const ContentBody = styled('div', { flex: 1, overflowY: 'auto' });

export default SmallModal;
