import { sample } from 'lodash';

const defaultCoverImages = [
  'https://asset.kemi.io/default-images/Kemi_Pattern_00.jpg',
  'https://asset.kemi.io/default-images/Kemi_Pattern_01.jpg',
  'https://asset.kemi.io/default-images/Kemi_Pattern_02.jpg',
  'https://asset.kemi.io/default-images/Kemi_Pattern_03.jpg',
  'https://asset.kemi.io/default-images/Kemi_Pattern_04.jpg',
  'https://asset.kemi.io/default-images/Kemi_Pattern_05.jpg',
  'https://asset.kemi.io/default-images/Kemi_Pattern_06.jpg',
  'https://asset.kemi.io/default-images/Kemi_Pattern_07.jpg',
  'https://asset.kemi.io/default-images/Kemi_Pattern_08.jpg',
  'https://asset.kemi.io/default-images/Kemi_Pattern_09.jpg',
];

const defaultProfileImages = [
  'https://asset.kemi.io/default-images/Kemi_Profile_00.jpg',
  'https://asset.kemi.io/default-images/Kemi_Profile_01.jpg',
  'https://asset.kemi.io/default-images/Kemi_Profile_02.jpg',
  'https://asset.kemi.io/default-images/Kemi_Profile_03.jpg',
  'https://asset.kemi.io/default-images/Kemi_Profile_04.jpg',
  'https://asset.kemi.io/default-images/Kemi_Profile_05.jpg',
  'https://asset.kemi.io/default-images/Kemi_Profile_06.jpg',
  'https://asset.kemi.io/default-images/Kemi_Profile_07.jpg',
  'https://asset.kemi.io/default-images/Kemi_Profile_08.jpg',
  'https://asset.kemi.io/default-images/Kemi_Profile_09.jpg',
];

export const randomCoverImage = () => {
  return sample(defaultCoverImages) || '';
};
export const randomProfileImage = () => {
  return sample(defaultProfileImages) || '';
};

export const isDefaultProfileImage = (image: string) => {
  return defaultProfileImages.includes(image);
};

export const isDefaultCoverImage = (image: string) => {
  return defaultCoverImages.includes(image);
};
