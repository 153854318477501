import Button from '@global/components/atoms/Button';
import Image from '@global/components/atoms/Image';
import { EVENT_TAG } from '@global/constants';
import { useLogFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import { KemiNextPage } from '@shared/types/next';
import background from '@static/media/500page-background.png';
import kemiAstro from '@static/media/kemi-astro.svg';
import { css, styled } from '@styles/stitches.config';

const NotFoundErrorPage: KemiNextPage = () => {
  useLogFirebase(
    UserInteractionType.PAGE_IN,
    EVENT_TAG.NOTFOUND_ERROR.SCREEN_OPEN_NOTFOUND_ERROR
  );

  return (
    <Block>
      <Image src={kemiAstro} layout={'fixed'} alt={'kemi-astro'} />
      <span className={textCss({ fontType: 'bold' })}>
        {i18n.t('k_page_not_found')}
      </span>
      <span className={textCss({ fontType: 'regular' })}>
        {i18n.t('k_the_page_you_are_looking_for_might_have_been_removed')}
      </span>
      <Button onClick={() => (location.href = '/')}>
        {i18n.t('k_go_home')}
      </Button>
    </Block>
  );
};

const Block = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: 16,
  textAlign: 'center',
  backgroundSize: 'cover',
  backgroundImage: `url(${background.src})`,
});

const textCss = css({
  color: '$white',
  marginBottom: 16,
  variants: {
    fontType: {
      bold: {
        fontType: 'bodyBold',
      },
      regular: {
        fontType: 'bodyRegular',
      },
    },
  },
});

NotFoundErrorPage.layoutType = 'KEMI';

export default NotFoundErrorPage;
